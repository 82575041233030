import React, { useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { Link as ScrollLink } from 'react-scroll';

import jade from '../../../../../asset/resource/jade.webm';
import jade_safari from '../../../../../asset/resource/jade_safari.mov';
import jade_main_bg from '../../../../../asset/resource/jade_main_bg.png';

import { useCheckMobile } from '../../../../hooks';

import './Title.scss';

export const Title = () => {
  const isMobile = useCheckMobile({ width: 767 });
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const [isStart1, setIsStart1] = useState(false);
  const [isStart2, setIsStart2] = useState(false);

  const handleView = (inView) => {
    if (inView) {
      const video = document.getElementById('box-video');
      setTimeout(function () {
        video.play();
      }, 2000);
    }
  };

  useEffect(() => {
    let isSafari = window.navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isSafari) setIsSafariBrowser(true);
  }, [isSafariBrowser]);

  return (
    <section
      className={cn('main-title')}
      style={{
        backgroundImage: `linear-gradient(to left, rgba(0,0,0,0.2), rgba(0,0,0,0.6)), url(${jade_main_bg})`,
      }}
    >
      <div className="main-title__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <h1>
            WHO IS <span>jade? </span>
            <br />
            <span className="main-title__small">UNDERSTAND THE USE CASE </span>
          </h1>
        </motion.div>
        <div className="main-title__heading">
          <p className={cn('main-title__description', isStart1 && 'visible')}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              onAnimationComplete={() => setIsStart1(true)}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
            >
              <strong>Meet Jade the use case for a virtual assistant</strong> developed using the SoftServe FSI
              Accelerator Generative AI Digital Expert ecosystem (GAIDEX). JADE’s use case is a Gen AI asset management
              financial advisor that in a one-on-one conversation, helps you navigate the often-complex world of
              financial terminology, products, and services. She is a personal, trusted guide on your journey to
              financial success, eliminating frustration and uncertainty on the way.
              <br />
              <br />
              By asking simple questions of the JADE avatar, you will discover your financial goals, learn your approach
              to risk and then define the level of assistance you need to manage your personal finances. This enables
              her to create a tailored client profile just for you. She helps you to be prepared and smoothly complete
              obligatory client onboarding and customer due diligence. This saves you time and accelerates your journey
              towards financial prosperity.
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              onAnimationComplete={() => setIsStart2(true)}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
            >
              <p className={cn('main-title__sub-description', isStart2 && 'visible')}>
                The JADE use case is here to enhance and underpin your financial well-being. Whether it's saving for
                your dream house, your children's education, or a worry-free retirement, she'll guide you through your
                requests. JADE explains investment directions and options, providing valuable insights on how to wisely
                manage your expenses. And she does it all with friendly, logical, and engaging conversations.
                <br />
                <br />
                Meet JADE, at SoftServe’s Booth #1009 at
                <span>
                  {' '}
                  Money 2020 Asia, <br />
                  Bangkok Thailand, from 23 to 25 April - 10am to 5pm
                </span>
              </p>
              <br />
              <br />
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1, delay: 0.6 }}
            >
              <ScrollLink smooth spy className="main-title__cta" to="contact-us">
                Book your immersive experience
              </ScrollLink>
            </motion.div>
          </p>

          <div className="main-title__inview">
            <InView triggerOnce onChange={handleView} threshold={0}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                onAnimationComplete={() => setIsStart2(true)}
                variants={
                  isMobile
                    ? {
                        visible: { x: 0, y: 0, opacity: 1 },
                      }
                    : {
                        visible: {
                          x: 0,
                          y: 0,
                          opacity: 1,
                        },
                        hidden: {
                          x: 300,
                          y: 200,
                          opacity: 0,
                        },
                      }
                }
                transition={{ type: 'anticipate', duration: 3 }}
              >
                <video id="box-video" className="main-title__video-content" width="100%" loop muted playsInline>
                  {isSafariBrowser ? (
                    <source src={jade_safari} type="video/mp4" />
                  ) : (
                    <source src={jade} type="video/webm" />
                  )}
                  Your browser is not supported!
                </video>
              </motion.div>
            </InView>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Title;
