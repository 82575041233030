import React from 'react';
import { motion } from 'framer-motion';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

//Assets
import onboarding from '../../../../../asset/resource/onboarding.png';
import planing from '../../../../../asset/resource/planing.png';
import education from '../../../../../asset/resource/education.png';
import support from '../../../../../asset/resource/support.png';

import { useCheckMobile } from '../../../../hooks';

import './UseCases.scss';

const UseCases = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="use-cases" className="use-cases">
      <div className="use-cases__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <div className="sub-title-wrapper">
            <span className="sub-title">Use Cases</span>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1, delay: 0.5 }}
        >
          <h2>Jade USE CASE AREAS OF EXPERTISE</h2>
        </motion.div>
        <div className="use-cases__slider-wrapper">
          <Carousel showArrows infiniteLoop showThumbs={false} showStatus={false}>
            <div className="use-cases__slide">
              <article>
                <h3>ONBOARDING</h3>
                <p>
                  JADE assists potential clients define their interests, collects essential information for client
                  profiling, and aligns appropriate account types and services with their goals. She seamlessly connects
                  clients' interests with the firm's offerings, facilitating a tailored financial journey.
                  <br />
                  <br />
                  JADE provides continuous guidance throughout the data-gathering process to ensure compliance with
                  regulations. She also conducts interviews that enable the completion of any necessary documentation
                  for service offerings and future engagements.
                </p>
              </article>
              <figure className="use-cases__image">
                <img width="100%" src={onboarding} className="onboarding-image" alt="onboarding image" />
              </figure>
            </div>
            <div className="use-cases__slide">
              <article>
                <h3>FINANCIAL HEALTH ANALYSIS AND IMPROVEMENT</h3>
                <p>
                  JADE engages the client in a conversation to uncover their present financial situation and desired
                  financial goals. She analyzes current income and spending habits.
                  <br />
                  <br />
                  JADE directs clients towards ways that will improve their financial well-being, prior to making
                  decisions about new savings programs or allocating funds to specific investments. She creates a
                  trusted environment that helps a client understand that his goals matter and they are the highest
                  priority for a financial firm. This encourages clients to be comfortable making bigger financial
                  decisions and commitments with the financial provider and its offerings.
                </p>
              </article>
              <figure className="use-cases__image">
                <img width="100%" src={planing} className="planing-image" alt="planing image" />
              </figure>
            </div>
            <div className="use-cases__slide">
              <article>
                <h3>FINANCIAL EDUCATION</h3>
                <p>
                  JADE assesses a client’s knowledge of financial matters and how confident he is in managing wealth.
                  She provides support to help the client gain a better understanding of various financial products,
                  instruments, and services, thereby increasing financial literacy and reducing confusion or
                  uncertainty.
                  <br />
                  <br />
                  JADE proves that the client's success is the top priority for the financial institution and therefore
                  she is always ready to educate the client to make sure he is better informed before making important
                  financial decisions.
                </p>
              </article>
              <figure className="use-cases__image">
                <img width="100%" src={education} className="education-image" alt="education image" />
              </figure>
            </div>
            <div className="use-cases__slide">
              <article>
                <h3>CUSTOMER SUPPORT</h3>
                <p>
                  JADE is available 24/7 to answer any client questions. Clients no longer need to wait to reach a
                  financial advisor or customer support operator, nor do they need to type out questions. Getting the
                  information required has become even simpler. Just ask your question out loud, and you'll receive
                  precise information, not standard responses.
                  <br />
                  <br />
                  JADE can also serve hundreds of customers who speak various languages simultaneously.
                  <br />
                  <br />
                  Communicating with JADE is like a conversation with an intelligent individual. It is a combination of
                  a digital process with human interaction. This creates a unique emotional bond between the customer
                  and the financial company.
                </p>
              </article>
              <figure className="use-cases__image">
                <img width="100%" src={support} className="support-image" alt="support image" />
              </figure>
            </div>
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
