import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { motion } from 'framer-motion';

//Assets
import icon_7 from '../../../../../asset/resource/icon_7.png';
import icon_8 from '../../../../../asset/resource/icon_8.png';

import { useCheckMobile } from '../../../../hooks';

import './TryItNow.scss';

export const TryItNow = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="try-it-out" className="how-to-use try-it-out">
      <div className="how-to-use__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <span className="sub-title">TRY IT OUT</span>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1, delay: 0.2 }}
        >
          <h2>
            MAKE AN EASY START TOWARDS <span>FINANCIAL GOALS</span>
          </h2>
        </motion.div>
        <div className="how-to-use__items-block">
          <p className="how-to-use__items-block-top-text">
            Get acquainted with the JADE use case and discover how easy it is to kickstart your journey toward a better
            financial future. Explore JADE at SoftServe’s Booth #6E01 at&nbsp;
            <span>Singapore Fintech Festival from 15 to 17 Nov – 10am to 6pm</span>
            &nbsp;and witness firsthand how Generative AI can reshape the financial landscape.
          </p>
          <div className="how-to-use__item">
            <div className="how-to-use__item-icons">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.5, delay: 0.2 }}
              >
                <img src={icon_7} alt="icon image" />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.5, delay: 0.4 }}
              >
                <img src={icon_8} alt="icon image" />
              </motion.div>
            </div>
            <div className="how-to-use__item-text">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.5, delay: 0.3 }}
              >
                <p>
                  Are you curious to understand how simple it is to initiate a positive change in your financial
                  situation? The JADE use case is here to educate and guide you towards your goals.
                </p>
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={
                  isMobile
                    ? {
                        visible: { y: 0, opacity: 1 },
                      }
                    : {
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 200, opacity: 0 },
                      }
                }
                transition={{ type: 'anticipate', duration: 0.5, delay: 0.5 }}
              >
                <p>
                  Are you interested in a seamless onboarding experience? The JADE use case ensures a smooth client
                  onboarding and due diligence process to save time and expedite your path to financial prosperity.
                </p>
              </motion.div>
            </div>
          </div>
          <div className="try-it-out__items-block-bottom">
            <p className="how-to-use__items-block-bottom-text">
              Explore JADE use case’s wide-ranging abilities that enhance the customer experience by tailoring
              communication to individual customer interests, adjusting the pace and tone, and streamlining mandatory
              procedures.
            </p>
          </div>
          <ScrollLink smooth spy className="try-it-out__items-block-bottom-button main-title__cta" to="contact-us">
            Book your immersive experience
          </ScrollLink>
        </div>
      </div>
    </section>
  );
};

export default TryItNow;
