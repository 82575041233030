import React from 'react';
import { motion } from 'framer-motion';

//Assets
import big_head_image from '../../../../../asset/resource/big_head_image.png';
import back_end_image from '../../../../../asset/resource/back_end_image.png';
import programming_background from '../../../../../asset/resource/programming_background.png';
import meet_jade_image from '../../../../../asset/resource/meet_jade_image.png';

import { useCheckMobile } from '../../../../hooks';

import './HowItWorks.scss';

export const HowItWorks = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="how-it-works" className="how-it-works">
      <div className="how-it-works__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.3 }}
        >
          <span className="sub-title">How It Works</span>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8 }}
        >
          <h2>
            WHAT’S THE TECHNOLOGY BEHIND <span>JADE?</span>
          </h2>
        </motion.div>
        <div className="how-it-works__blocks">
          <div className="how-it-works__block one">
            <article>
              <h3>Front end – digital avatar</h3>
              <p>
                The virtual essence of JADE’s use case comes to life through advanced digital avatar technology.
                Utilizing the Avatar Cloud Engine (ACE) and NVIDIA Riva, along with the A2F component, SoftServe has
                created a lifelike representation that captures both speech and facial expressions with uncanny
                naturalness. From model creation by the Character's Creator tool, to final integration, every detail of
                JADE’s appearance is designed to facilitate a seamless and engaging interaction.
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <h3>Core brain – llm and generative ai</h3>
              <p>
                Empowered by Large Language Models (LLMs) and GenAI technology, JADE can recognize the request,
                understand the context and answer the question in the most appropriate manner for that moment. She can
                handle complex requests, simplify or explain in detail, and perform like any selected or pre-defined
                person. This brings conversations to the next level, not just from a personalization perspective, but
                also from a human-like perspective.
              </p>
            </article>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: 200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.8 }}
            >
              <figure className="how-it-works__image">
                <img width="100%" src={big_head_image} className="big_head_image" alt="generative ai image" />
              </figure>
            </motion.div>
          </div>

          <div className="how-it-works__block one">
            <article>
              <h3>Back end – integration with financial firm it ecosystem</h3>
              <p>
                An integration of the virtual shape of a JADE use case with existing systems and databases not only
                supports the conversation and level of detail, but also speeds up the process of context understanding.
                This enables it to provide more relevant answers, supported by historical and personal data. The more
                data financial firms can leverage for this purpose the more personalized the information will be that is
                presented to the client.
              </p>
            </article>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: 200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.8 }}
            >
              <figure className="how-it-works__image">
                <img width="100%" src={back_end_image} className="generative_ai" alt="generative ai image" />
              </figure>
            </motion.div>
          </div>

          <div className="how-it-works__block two">
            <article>
              <h3>Data governance and regulations</h3>
              <p>
                Data governance and regulatory compliance are challenging, but necessary obligations for solutions like
                these. However, we have created an approach that successfully moves the process forward, step-by-step,
                in line with specific client needs in each region. Or it can start by implementing internal solutions
                based on existing internal data. This solution is a tool for initial client interactions and to support
                better client decision-making, while maintaining humans in the loop where required for any critical
                financial product related decisions.
              </p>
            </article>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { x: 0, opacity: 1 },
                    }
                  : {
                      visible: { x: 0, opacity: 1, scale: 1 },
                      hidden: { x: -200, opacity: 0, scale: 0.8 },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.8 }}
            >
              <figure className="how-it-works__image">
                <img
                  src={programming_background}
                  className="programming_background_image"
                  alt="programming data image"
                />
              </figure>
            </motion.div>
          </div>
        </div>

        <div className="how-it-works__video">
          <h2>Watch Jade in Action!</h2>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={
              isMobile
                ? {
                    visible: { y: 0, opacity: 1 },
                  }
                : {
                    visible: { y: 0, opacity: 1, scale: 1 },
                    hidden: { y: 200, opacity: 0, scale: 0.8 },
                  }
            }
            transition={{ type: 'anticipate', duration: 1.2 }}
          >
            <a
              href="https://www.youtube.com/watch?v=xKraPka_IzA "
              role="button"
              aria-label="video block"
              target="_blank"
              className="how-it-works__video-link"
            >
              <figure className="how-it-works__video-image">
                <div className="how-it-works__video-image-wrapper">
                  <img src={meet_jade_image} alt="video image" />
                </div>
                <figcaption>SOFTSERVE AT SINGAPORE FINTECH FESTIVAL 2023</figcaption>
              </figure>
            </a>
          </motion.div>
          {/* <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={
              isMobile
                ? {
                    visible: { y: 0, opacity: 1 },
                  }
                : {
                    visible: { y: 0, opacity: 1, scale: 1 },
                    hidden: { y: 200, opacity: 0, scale: 0.8 },
                  }
            }
            transition={{ type: 'anticipate', duration: 1.2 }}
          >
            <a
              href="https://youtu.be/Jz4TniDNq9Y"
              role="button"
              aria-label="video block"
              target="_blank"
              className="how-it-works__video-link"
            >
              <figure className="how-it-works__video-image">
                <div className="how-it-works__video-image-wrapper">
                  <img src={behind_the_scene_image} alt="video image" />
                </div>
                <figcaption>BEHIND THE SCENES</figcaption>
              </figure>
            </a>
          </motion.div> */}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
